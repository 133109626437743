.photo_size{
    width: 100px;
    height: 100px;
}

.back-color {
	background-color: rgb(235, 235, 235);
}

.menu-color {
	background-color: rgb(255, 255, 255);
}

.title_style{
    font-family: 'Maven Pro', sans-serif;
    font-size: xx-large;
    text-align: left;
}

.text_form{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 10pt;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 10px;
}

.text_form_radio{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 10pt;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 0px;
}


.text_central_ajuda_titulo{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 11pt;
    font-style: inherit;
    color: rgb(110, 110, 110);
    margin-bottom: 20px;
    margin-top: 20px;
}

.text_central_ajuda{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 10pt;
    color: rgb(110, 110, 110);
    margin-bottom: 20px;
    margin-top: 20px;
}


.button_form{
    margin-top: 30px;
    margin-bottom: 20px;
}

.text_link{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 10pt;
    color: rgb(21, 106, 235);
    margin-bottom: 0px;
    margin-top: 10px;
}

.img_lateral{
    margin-bottom: 0px;
    margin-top: 90px;
}

.text_big{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 14pt;
    font-style: inherit;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 0px;
}

.text_big_center{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 14pt;
    font-style: inherit;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
    justify-content: center;
}

.text_subTitulo{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 11pt;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 0px;
}

.text_medium{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 11pt;
    font-style: inherit;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 0px;
}

.text_medium_center{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 11pt;
    font-style: inherit;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: center;
    justify-content: center;
}

.text_banco{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 9pt;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 10px;
}

.text_banco_center{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 9pt;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 10px;
    text-align: center;
    justify-content: center;
}

.text_small{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 9pt;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 0px;
}

.text_small_erro{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 9pt;
    color: #c50000;
    margin-bottom: 0px;
    margin-top: 0px;
}

.text_badge{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 8pt;
    color: #ffffff;
    margin-bottom: 0px;
    margin-top: 0px;
}

.text_alert{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 9pt;
    color: rgb(110, 110, 110);
    margin-bottom: 0px;
    margin-top: 0px;
}

.card_semBorda{
    border: 'none';
}

.no-underline {
    text-decoration: none;
  }

.barra{
  border-top: 1px solid;
  color: rgb(110, 110, 110);
  width: 90%;
  text-align: center;
}